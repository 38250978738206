import React from 'react';

import CSS from './Button.module.css';

const button = (props) => (
	<button
        type="button"
		className={[CSS.Button, CSS[props.btnType]].join(' ')}
        disabled={props.disabled}
		onClick={props.clicked}
	>{props.children}</button>
);

export default button;