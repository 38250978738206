import React from 'react';
import { FaTimes } from 'react-icons/fa';

import CSS from './RemoveButton.module.css';

const removeButton = (props) => (
    <div
        style={props.style}
        className={props.inset ? [CSS.RemoveButton, CSS.InsetButton].join(' ') : CSS.RemoveButton} 
        onClick={props.clicked}
    >
        <FaTimes/>
    </div>
);

export default removeButton; 