import React, { Component } from 'react';

import InputWindowButtonRow from './InputWindowButtonRow/InputWindowButtonRow';
import RemoveButton from '../RemoveButton/RemoveButton';

import CSS from './InputWindow.module.css';

const useFocus = () => {
    const ref = React.createRef()
    const setFocus = () => {ref.current && ref.current.focus()}

    return {setFocus, ref} 
}

class InputWindow extends Component {
    constructor(props){
        super(props);
        this.inputFocus = useFocus();
    }

    componentDidMount(){
        this.inputFocus.setFocus();
    }

    render() {
        let inputChildren = null;
        let inputTitleString = '';

        switch(this.props.type) {
            case('markdown'):
                inputTitleString = 'Markdown Cell';
                inputChildren = <textarea
                                    ref={this.inputFocus.ref}
                                    rows="10"
                                    value={this.props.value}
                                    placeholder="Markdown text" 
                                    onChange={(event) => this.props.inputChangedMethod(event, 'markdown', this.props.index)}
                                />
                break;
            case('uploaded-image'):
            case('image'):
                inputTitleString = 'Image Upload';
                inputChildren = <div style={{margin: "1em", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                    <input 
                                        type="file"
                                        onChange={(event) => this.props.uploadImageMethod(event, this.props.index)}
                                    />
                                    <input
                                        ref={this.inputFocus.ref}
                                        type="text" 
                                        value={this.props.value}
                                        placeholder="Image caption" 
                                        onChange={(event) => this.props.inputChangedMethod(event, 'caption', this.props.index)}
                                    />
                                </div>
                break;
            case('embed'):
                inputTitleString = 'Iframe Embedded Element';
                inputChildren = <input 
                                    ref={this.inputFocus.ref}
                                    type="text" 
                                    value={this.props.value}
                                    placeholder="Paste embed src URL here" 
                                    onChange={(event) => this.props.inputChangedMethod(event, 'embed', this.props.index)}
                                />
                break;
            default:
        }

        return(
            <div className={CSS.WriteUpElementInputWindow}>
                <div className={CSS.WriteUpElementInputWindowHeader}>
                    <div>{`${this.props.index + 1} - ${inputTitleString}`}</div>
                    <div style={{display:'flex', alignItems: 'center'}}>
                        {this.props.buttons ? <InputWindowButtonRow mainButton={this.props.type} buttons={this.props.buttons}/> : null}
                        {this.props.removeMethod ? <RemoveButton clicked={this.props.removeMethod}/> : null}
                    </div>
                </div>
                <div className={CSS.WriteUpElementInputContainer}>
                    {inputChildren}
                </div>
            </div>
        )
    }
}

export default InputWindow; 