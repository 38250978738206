import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { authRef } from './Firebase/Firebase';

import { UserProvider } from './context/UserContext';

import Layout from './containers/Layout/Layout';
import FrontPage from './containers/FrontPage/FrontPage'
import Auth from './containers/Admin/Auth/Auth';
import PostEditor from './containers/PostEditor/PostEditor';
import BioEditor from './containers/BioEditor/BioEditor';
import AdminPanel from './containers/Admin/AdminPanel/AdminPanel';
import CatchAll from './containers/CatchAll/CatchAll';
import WriteUp from './components/WriteUp/WriteUp';

import Spinner from './components/UI/Spinner/Spinner';
import './App.css';

class App extends Component {
    state = {
        loading: true,
        authToken: null
    }

    componentDidMount() {
        authRef.onAuthStateChanged((user) => {
            if (user) {
                this.setState({loading: false, authToken: user.uid})
            } else {
                this.setState({loading: false, authToken: null})
            }
        });
    }

    render () {
        const auth = this.state.authToken === process.env.REACT_APP_UID
        return (
            <UserProvider value={{user: auth ? true : false }}>
                <Layout loggedIn={auth}>
                    {
                        this.state.loading
                        ?   <div className='AppSpinnerContainer'><Spinner/></div>
                        :   auth
                            ?   <Switch>
                                    <Route
                                        path="/portfolio/:projectName"
                                        render={(props) => <WriteUp {...props}/>}
                                    />
                                    <Route path="/admin" exact component={AdminPanel} />
                                    <Route path="/post-editor/" exact component={PostEditor} />
                                    <Route path="/post-editor/:postId" component={PostEditor} />
                                    <Route path="/bio-editor" exact component={BioEditor} />
                                    <Route path="/signOut">
                                        <Redirect to="/"/>
                                    </Route>
                                    <Route path="/" exact component={FrontPage} />
                                    <Route path="*">
                                         <CatchAll text="Page not found."/>
                                    </Route>
                                </Switch>
                            :   <Switch>
                                    <Route
                                        path="/portfolio/:projectName"
                                        render={(props) => <WriteUp {...props}/>}
                                    />
                                    <Route path="/admin" exact component={Auth} />
                                    <Route path="/post-editor/">
                                        <CatchAll text="You do not have permission to view this page."/>
                                    </Route>
                                    <Route path="/bio-editor">
                                        <CatchAll text="You do not have permission to view this page."/>
                                    </Route>
                                    <Route path="/sign-out">
                                        <Redirect to="/"/>
                                    </Route>
                                    <Route path="/" exact component={FrontPage} />
                                    <Route path="*">
                                         <CatchAll text="Page not found."/>
                                    </Route>
                                </Switch>
                    }
                </Layout>
            </UserProvider>
        )
    };
}

export default App;
