import React from 'react';

import CSS from './ActionButton.module.css';

const actionButton = (props) => (
    <div className={CSS.ActionButton} onClick={props.clicked}>
        {props.icon}
    </div>
);

export default actionButton; 