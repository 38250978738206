import React from 'react';

import CSS from './ContactIcon.module.css';

const contactIcon = (props) => (
    <div className={CSS.IconContainer}>
        <a
            className={CSS.Icon}
            href={props.link} 
            target="_blank" 
            rel="noopener noreferrer"
        >
            {props.icon}
        </a>
    </div>
);

export default contactIcon; 