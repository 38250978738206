import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import UserContext from '../../../context/UserContext';

import Logo from '../../Logo/Logo';
import NavItems from '../NavItems/NavItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

import CSS from './Toolbar.module.css';

class Toolbar extends Component {
    static contextType = UserContext;

    render(){
        return(
            <header className={CSS.Toolbar}>
                    <DrawerToggle clicked={this.props.drawerToggleClicked}/>        
                    <div className={CSS.Logo}>
                        <NavLink
                            exact
                            to="/"
                        >
                            <Logo />
                        </NavLink>
                    </div>
                    <nav className={CSS.DesktopOnly}>
                        <NavItems signedIn={this.context.user}/>
                    </nav>
            </header>
        )
    }
}

export default Toolbar;