import React from 'react';

import CSS from './InputWindowButton.module.css';

const inputWindowButton = (props) => (
    <div 
        className={CSS.InputWindowButton} 
        onClick={props.clicked}
        style={props.style}
    >{props.icon}</div>
);

export default inputWindowButton; 