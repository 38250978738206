import React from 'react';

import FirebaseImage from '../../../../Firebase/FirebaseImage/FirebaseImage';

import CSS from './ImageContainer.module.css';

const imageContainer = (props) => {
    let renderedImage = null;
    
    if (props.image === null) {
        renderedImage = <img src='https://via.placeholder.com/275x275' alt=''/> 
    } else if (typeof(props.image) === 'string') {
        if(props.image.substring(0,4) === "data") {
            renderedImage = <img src={props.image} alt=''/> 
        } else {
            renderedImage = <FirebaseImage src={props.image}/>
        }
    }
    
    return(
        <div className={CSS.ImageContainer}>
            {renderedImage}
            <p>{props.caption}</p>
        </div>
    )
}

export default imageContainer; 