import React, { Component } from 'react';
import { storageRef } from '../Firebase';

import DotLoader from '../../components/UI/DotLoader/DotLoader';

class FirebaseImage extends Component {
    state = {
        src: '',
        loading: true
    }

    componentDidMount() {
        const imageStorageRef = storageRef.ref()

        // get a reference to the thumbnail image within the storage bucket
        const thumbnailRef = imageStorageRef.child(this.props.src);

        //get the thumbnail image url and update the project object
        thumbnailRef.getDownloadURL()
        .then((url) => {
            this.setState({
                src: url,
                loading: false
            })
        }).catch((error)=> {console.log(error)});
    }

    render() {
        let renderedComponent = (this.state.loading) ? <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><DotLoader/></div> : <img src={this.state.src} alt={this.props.alt}/>


        return (renderedComponent);
    }
};

export default FirebaseImage;