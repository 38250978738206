import React from 'react';
import { FaGithubSquare, FaLinkedin, FaEnvelopeSquare, FaFileAlt } from "react-icons/fa";
import pdf from '../../../assets/files/GabrielMGomez_Resume.pdf';

import ContactIcon from './ContactIcon/ContactIcon';

import CSS from './ContactIcons.module.css';

const contactIcons = () => {
    const icons = [
        {
            link: 'https://github.com/ggome009',
            icon: <FaGithubSquare/>,
        },
        {
            link: 'https://www.linkedin.com/in/gabriel-m-gomez/',
            icon: <FaLinkedin/>,
        },
        {
            link: 'mailto:gmgomez009@gmail.com',
            icon: <FaEnvelopeSquare/>,
        },
        {
            link: pdf,
            icon: <FaFileAlt/>,
        }
    ];

    return(
        <div className={CSS.Contact}>
            {
                icons.map(data =>
                    <ContactIcon
                        key={data.link}
                        icon={data.icon}
                        link={data.link}
                    />
                )
            }
        </div>
    )
};

export default contactIcons;
