import React from 'react';

import CSS from './Buzzword.module.css';

const buzzword = (props) => (
    props.children !== ''
    ?   <span className={CSS.Buzzword} key={buzzword}>{props.children}</span>
    :   null
);

export default buzzword; 