import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';

import { databaseRef } from '../../Firebase/Firebase';

import CSS from './About.module.css';

class About extends Component {
    state = {
        content: ''
    }

    componentDidMount() {
        const aboutRef = databaseRef.ref('/about');
        
        aboutRef.on('value', (snapshot) => {
            this.setState({
                content: snapshot.val().content
            });
        });
    }


    render() {
        return(
            <div className={CSS.Row}>
                <Markdown className={CSS.InfoContainer}>{this.state.content}</Markdown>
            </div>
        );
    }
}

export default About; 