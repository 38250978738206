import React from 'react';

import InputWindowButton from './InputWindowButton/InputWindowButton';
import CSS from './InputWindowButtonRow.module.css';

const inputWindowButtonRow = (props) => (

    <div className={CSS.InputWindowButtonRow}>
        {
            props.buttons.map((button) => (
                <InputWindowButton style={props.mainButton === button.title ? {color: 'white', cursor: 'default'} : null} key={button.title} icon={button.icon} clicked={props.mainButton === button.title ? null : button.clicked}/>
            ))
        }
    </div>
);

export default inputWindowButtonRow; 