import React from 'react';
import { FaEdit, FaRegTimesCircle, FaRegListAlt, FaEyeSlash, FaEye } from "react-icons/fa";

import ActionButton from './ActionButton/ActionButton';
import PortfolioCard from '../PortfolioCard/PortfolioCard';

import CSS from './AdminCard.module.css';

const adminCard = (props) => {
    const {thumbnailImage, visible, ...info} = props.project
    
    return(
        <div className={CSS.AdminCardContainer}>
            <div className={CSS.CardHolder} style={{ opacity: visible ? '1' : '0.3' }}>
                <PortfolioCard
                    info={info}
                    image={thumbnailImage}
                />
            </div>
            <div className={CSS.PostActionHolder}>
                <ActionButton 
                    icon={<FaEdit/>}
                    clicked={props.editMethod}
                />
                <ActionButton 
                    icon={<FaRegTimesCircle/>}
                    clicked={props.deleteMethod}
                />
                <ActionButton 
                    icon={<FaRegListAlt/>}
                    clicked={props.showMetaDataMethod}
                />
                <ActionButton 
                    icon={visible ? <FaEyeSlash/> : <FaEye/>}
                    clicked={props.toggleVisibilityMethod}
                />
            </div>    
        </div>
    )
}



export default adminCard; 