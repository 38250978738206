import React from 'react';

import HeaderButtonRow from '../HeaderButtonRow/HeaderButtonRow';

import CSS from './InputLabel.module.css';

const inputLabel = (props) => (
    <label>
        <div className={CSS.HeaderRow}>
            <p className={CSS.LabelHeader}>{props.title}</p>
            {
                props.buttons 
                ?   <HeaderButtonRow style={{margin: '1em'}} buttons={props.buttons}/>
                :   null
            }
        </div>
        {props.children}
    </label>
);

export default inputLabel; 
