import React, { Component } from 'react';

import RemoveButton from '../RemoveButton/RemoveButton';

import CSS from './RemovableInput.module.css';

class RemovableInput extends Component {
    state = {
        hovered: false
    }

    hoverHandler = (entered) => {
        this.setState({hovered: entered});
    }

    render() {
        return (
            <div onMouseEnter={() => this.hoverHandler(true)} onMouseLeave={() => this.hoverHandler(false)} className={CSS.RemovableInput}>
                <RemoveButton 
                    clicked={this.props.removeMethod}
                    inset
                    style={this.state.hovered ? null : {opacity: '0'}}
                />
                <input 
                    type="text" 
                    value={this.props.value} 
                    placeholder={this.props.placeholder}
                    onChange={this.props.changedMethod}
                />
            </div>
        );
    }
};

export default RemovableInput;