import React, { Component } from 'react';
import { databaseRef, storageRef } from '../../Firebase/Firebase.js';
import { FaTag, FaPenSquare, FaImage, FaPlus, FaCode } from 'react-icons/fa';
import { v1 as uuidv1 } from 'uuid';

import PortfolioCard from '../../components/PortfolioCard/PortfolioCard';
import WriteUpPreview from '../../components/WriteUp/WriteUpPreview';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import PostEditorModal from './PostEditorModal/PostEditorModal';
import InputLabel from '../../components/UI/InputLabel/InputLabel';
import InputWindow from '../../components/UI/InputWindow/InputWindow';
import Aux from '../../hoc/Aux/Aux';
import RemovableInput from '../../components/UI/RemovableInput/RemovableInput'

import CSS from './PostEditor.module.css';

class PostEditor extends Component {
    state = {
        currentProject: {
            thumbnailImage: null,
            title: "",
            links: [
                {
                    type: 'INTERNAL',
                    address: '',
                    displayText: 'Info'            
                },
                {
                    type: 'EXTERNAL', 
                    address: '', 
                    displayText: '' 
                },
                {
                    type: 'EXTERNAL', 
                    address: '', 
                    displayText: '' 
                }
            ],
            description: "",
            buzzwords: [],
            writeUp: []
        },
        showModal: false,
        update: false
    }

    componentDidMount() {

        if(Object.keys(this.props.match.params).length) {
            let projectsRef = databaseRef.ref(`projects/${this.props.match.params.postId}`);
        
            //
            projectsRef.once('value', (snapshot) => {
                let projectData = {...snapshot.val()};

                this.setState({
                    currentProject: projectData,
                    update: true
                });
            });
            
        }
    }

    getCopyOfCurrentProject = () => {
        // clone each of the immutable datatypes in the current project
        let clonedLinks = [];
        this.state.currentProject.links.forEach((link) => { clonedLinks.push({...link})});
        
        let clonedWriteUp = [];
        this.state.currentProject.writeUp.forEach((writeUpElement) => { clonedWriteUp.push({...writeUpElement})});
        
        let clonedBuzzwords = [...this.state.currentProject.buzzwords]



        let clonedImage = null
        if(typeof(this.state.currentProject.thumbnailImage) === 'string') {
            clonedImage = this.state.currentProject.thumbnailImage.slice();
        } else if (typeof(this.state.currentProject.thumbnailImage) === 'object') {
            clonedImage = {...this.state.currentProject.thumbnailImage}
        }

        // updated copy is a full clone of the current project
        const copy = {
            ...this.state.currentProject,
            thumbnailImage: clonedImage,
            links: clonedLinks,
            writeUp: clonedWriteUp,
            buzzwords: clonedBuzzwords
        };

        return copy;
    }

    handleInputChange = (event, inputIdentifier, index) => {
        event.preventDefault();
        
        let updatedProject = {...this.getCopyOfCurrentProject()};

        // the string that the user has changed
        let updatedField = event.target.value;

        switch(inputIdentifier){
            case('title'):
                updatedProject[inputIdentifier] = updatedField;
                break;
            case('description'):
                updatedProject[inputIdentifier] = updatedField;
                break;
            case('address'):
                updatedProject.links[index].address = updatedField;
                break;
            case('displayText'):
                updatedProject.links[index].displayText = updatedField;
                break;
            case('markdown'):
                updatedProject.writeUp[index].payload = updatedField;
                break;
            case('caption'):
                updatedProject.writeUp[index].payload.caption = updatedField;
                break;
            case('buzzword'):
                updatedProject.buzzwords[index] = updatedField;
                break;
            case('embed'):
                updatedProject.writeUp[index].payload.url = updatedField;
                break;
            default:
                updatedProject[inputIdentifier] = updatedField;
        }
        
        this.setState({
            currentProject: {...updatedProject}
        });
    } 

    handleUploadThumbnailImage = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onloadend = () => {
            let updatedProject = {...this.getCopyOfCurrentProject()};

            updatedProject.thumbnailImage = {
                file: file,
                previewURL: reader.result
            };

            this.setState({currentProject: updatedProject});
        }

        reader.readAsDataURL(file)
    }

    handleUploadWriteUpImage = (event, inputIdentifier) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onloadend = () => {
            let updatedProject = this.state.currentProject;

            updatedProject.writeUp[inputIdentifier].payload = {
                ...updatedProject.writeUp[inputIdentifier].payload,
                file: file,
                previewURL: reader.result
            };

            this.setState({currentProject: updatedProject});
        }

        reader.readAsDataURL(file)
    }

    handleAddElementToArrayInProject = (arrayIdentifier, type) => {
        // create a clone of the current project
        let updatedProject = {...this.getCopyOfCurrentProject()};

        // assign the value of the new element based on the arrayIdentifier
        switch(arrayIdentifier){
            case('buzzwords'):
                updatedProject[arrayIdentifier] = [...updatedProject[arrayIdentifier]]
                updatedProject[arrayIdentifier].push("");
                break;
            case('writeUp'):
                let addedArrayElement = null;
                switch(type) {
                    case('markdown'):
                        addedArrayElement = {type: 'markdown', id: uuidv1(), payload: ""};
                        break;
                    case('uploaded-image'):
                        addedArrayElement = {type: "uploaded-image", id: uuidv1(), payload: { file: null, previewURL: null, caption: "" }} ;
                        break;
                    case('embed'):
                        addedArrayElement = {type: "embed", id: uuidv1(), payload: { url: ''}};
                        break;
                    default:
                }
                updatedProject[arrayIdentifier] = [...updatedProject[arrayIdentifier], addedArrayElement];
                break;
            default:
        }      

        this.setState({
            currentProject: updatedProject
        });
    }

    handleRemoveElementFromArrayInProject = (arrayIdentifier, index) => {
        let updatedProject = this.getCopyOfCurrentProject();
        
        const updatedArray = [...updatedProject[arrayIdentifier]];
        
        updatedProject[arrayIdentifier] = updatedArray.filter((element, i) => i !== index);

        this.setState({
            currentProject: updatedProject
        });
    }

    handleChangeWriteUpElementType = (index, targetType) => {
        let updatedProject = this.getCopyOfCurrentProject();

        let writeUpElement = updatedProject.writeUp[index];
        switch(targetType) {
            case('markdown'):
                updatedProject.writeUp[index] = {...writeUpElement, type: 'markdown', payload: ""};
                break;
            case('uploaded-image'):
                updatedProject.writeUp[index] = {...writeUpElement, type: "uploaded-image", payload: { file: null, previewURL: null, caption: "" }} ;
                break;
            case('embed'):
                updatedProject.writeUp[index] = {...writeUpElement, type: "embed", payload: { url: ''}};
                break;
            default:
        }

        this.setState({
            currentProject: updatedProject
        });
    }

    handleSubmitProject = () => {
        const timeStamp = Number(new Date())
        const date = new Date(timeStamp).toLocaleString();

        let data = {
            ...this.getCopyOfCurrentProject(),
            timeData: {
                dateCreated: {
                    timeStamp: timeStamp,
                    date: date 
                },
                dateLastEdited: {
                    timeStamp: timeStamp,
                    date: date
                }
            }
        };

        // get reference to storage bucket
        const imageStorageRef = storageRef.ref();
        
        // get name of folder for individual project
        const filename = data.title.replace(/\s/g, '');
        
        // get reference to path name in storage bucket for individual project
        const projectThumbnailReference = imageStorageRef.child(`projects/${filename}/${filename}-thumbnail`);

        // push thumbnail image to storage bucket
        projectThumbnailReference.put(data.thumbnailImage.file)
                        .then((snapshot) => {
                            console.log("uploaded thumbnail image")
                        });

        // update data.thumbnailImage to hold the path to the thumbnail image in the storage bucket
        data.thumbnailImage = `projects/${filename}/${filename}-thumbnail`;
        
        // handle images in the writeUp array
        data.writeUp = data.writeUp.map((writeUpElement, i) => {
            if(writeUpElement.type === "uploaded-image") { // if the writeUp element is an image
                // get a reference to the writeUp folder within the project folder
                const writeUpPhotoReference = imageStorageRef.child(`projects/${filename}/writeUp/writeUpImage-${i}`);

                // push the image file to the writeUp folder
                writeUpPhotoReference.put(writeUpElement.payload.file)
                        .then((snapshot) => {
                            console.log(`uploaded writeUp image ${i}`)
                        });

                // return a writeUpElement with type: 'image', and appropriate payload
                return {
                    type: 'image',
                    id: writeUpElement.id,
                    payload: {
                        path: `projects/${filename}/writeUp/writeUpImage-${i}`,
                        caption: writeUpElement.payload.caption
                    }
                }  
            } else { // return the writeUpElement, unchanged
                return writeUpElement
            }
        })

        const projectsDatabaseReference = databaseRef.ref('projects');
        projectsDatabaseReference.push(data);
    }

    handleUpdateProject = () => {
        const projectsDatabaseReference = databaseRef.ref(`projects`);

        let data = {...this.getCopyOfCurrentProject()};

        const timeStamp = Number(new Date())
        const date = new Date(timeStamp).toLocaleString();

        data.timeData.dateLastEdited = {
            timeStamp: timeStamp,
            date: date
        };

        const postId = this.props.match.params.postId;
        
        projectsDatabaseReference.update({[postId]: data});
    }

    handleCloseModal = () => {
        this.setState({ showModal: false })
    }

    handleShowModal = () => {
        this.setState({ showModal: true })
    }

    render() {
        const { thumbnailImage, writeUp, ...cardData} = this.state.currentProject
        return (
            <div>
                <Modal 
                    closeModalMethod={this.handleCloseModal} 
                    show={this.state.showModal}
                >
                    <PostEditorModal 
                        title={this.state.currentProject.title}
                        closeModalMethod={this.handleCloseModal}
                        confirmMethod={this.state.update ? this.handleUpdateProject : this.handleSubmitProject}
                        confirmText={this.state.update ? "Save changes" : "Submit"}
                        discriptiveText={this.state.update ? "Any revisions will be updated immediately." : "Your post will be added to your admin panel, but hidden from your portfolio."}
                    />
                </Modal>
                <div className={CSS.CardFormContainer}>
                    <div className={CSS.PortfolioCardContainer}>
                        <PortfolioCard
                            image={thumbnailImage}
                            info={cardData}
                        />
                    </div>
                    <div className={CSS.FormContainer} project={this.state.currentProject}>
                        <form className={CSS.CockpitForm}>
                            <InputLabel title="Thumbnail Image">
                                <input 
                                    type="file" 
                                    onChange={(event) => this.handleUploadThumbnailImage(event)}
                                />
                            </InputLabel>
                            <InputLabel title="Project Title">
                                <input 
                                    type="text" 
                                    value={this.state.currentProject.title} 
                                    placeholder="Title" onChange={(event) => this.handleInputChange(event, 'title', -1)}
                                />
                            </InputLabel>
                            <InputLabel title="Links">
                                {
                                    this.state.currentProject.links.map((link, i) => (
                                        <div key={i} className={CSS.LinkInputContainer}>
                                            <div>
                                                {(this.state.currentProject.links[i].displayText === "Info") ? "Write Up Link" :`Extra Link ${i}`}
                                            </div>
                                            <div className={[CSS.InputHalf, CSS.InputLeft].join(' ')}>
                                                <input
                                                    type="text" 
                                                    value={this.state.currentProject.links[i].displayText} 
                                                    placeholder="Display Text" 
                                                    onChange={(event) => this.handleInputChange(event, 'displayText', i)}
                                                />
                                            </div>
                                            <div className={[CSS.InputHalf, CSS.InputRight].join(' ')}>
                                                <input 
                                                    type="text" 
                                                    value={this.state.currentProject.links[i].address} 
                                                    placeholder={i === 0 ? "Page URL" : "Linked URL"}
                                                    onChange={(event) => this.handleInputChange(event, 'address', i)}
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </InputLabel>
                            <InputLabel title="Description">
                                <input 
                                    type="text" 
                                    value={this.state.currentProject.description} 
                                    placeholder="Description" 
                                    onChange={(event) => this.handleInputChange(event, 'description')}
                                />
                            </InputLabel>
                            <InputLabel 
                                title="Tags"
                                buttons={[
                                    {
                                        clicked: () => this.handleAddElementToArrayInProject('buzzwords'), 
                                        icon: <Aux><FaTag/><FaPlus/></Aux>,
                                        title: 'remove'
                                    }
                                ]}
                            >
                                {
                                    this.state.currentProject.buzzwords.map((buzzword, i) => (
                                        <RemovableInput
                                            key={i}
                                            placeholder={`Tag ${i + 1}`}
                                            value={this.state.currentProject.buzzwords[i]}
                                            removeMethod={() => this.handleRemoveElementFromArrayInProject('buzzwords', i)}
                                            changedMethod={(event) => this.handleInputChange(event, 'buzzword', i)}
                                        />
                                    ))
                                }
                            </InputLabel>
                            <InputLabel
                                title="Post Content"
                                buttons={[
                                    {
                                        clicked: () => this.handleAddElementToArrayInProject('writeUp', 'markdown'),
                                        icon: <FaPenSquare/>,
                                        title: 'markdown'
                                    },
                                    {
                                        clicked: () => this.handleAddElementToArrayInProject('writeUp', 'uploaded-image'),
                                        icon: <FaImage/>,
                                        title: 'image'
                                    },
                                    {
                                        clicked: () => this.handleAddElementToArrayInProject('writeUp', 'embed'),
                                        icon: <FaCode/>,
                                        title: 'embed'
                                    }
                                ]}
                            >
                                {
                                    this.state.currentProject.writeUp.map((writeUpElement, i) => {
                                        let valueReference = null;

                                        switch(writeUpElement.type) {
                                            case('markdown'):
                                                valueReference = this.state.currentProject.writeUp[i].payload
                                                break;
                                            case('uploaded-image'):
                                            case('image'):
                                                valueReference = this.state.currentProject.writeUp[i].payload.caption
                                                break;
                                            case('embed'):
                                                valueReference = this.state.currentProject.writeUp[i].payload.url
                                                break;
                                            default:
                                        }
                                        return(
                                            <InputWindow
                                                key={i}
                                                index={i}
                                                value={valueReference}
                                                type={writeUpElement.type === 'uploaded-image' ? 'image' : writeUpElement.type}
                                                inputChangedMethod={this.handleInputChange}
                                                uploadImageMethod={this.handleUploadWriteUpImage}
                                                removeMethod={() => this.handleRemoveElementFromArrayInProject('writeUp', i)}
                                                buttons={[
                                                    {
                                                        title: 'markdown',
                                                        clicked: () => this.handleChangeWriteUpElementType(i, 'markdown'),
                                                        icon: <FaPenSquare/>
                                                    },
                                                    {
                                                        title: 'image',
                                                        clicked: () => this.handleChangeWriteUpElementType(i, 'uploaded-image'),
                                                        icon: <FaImage/>
                                                    },
                                                    {
                                                        title: 'embed',
                                                        clicked: () => this.handleChangeWriteUpElementType(i, 'embed'),
                                                        icon: <FaCode/>
                                                    }
                                                ]}
                                            />
                                        )
                                    })    
                                }
                                </InputLabel>
                            <Button clicked={this.handleShowModal}>{this.state.update ? "Save changes" : "Submit"}</Button>
                        </form>
                    </div>
                </div>
                <WriteUpPreview
                    title={this.state.currentProject.title}
                    description={this.state.currentProject.description}
                    buzzwords={this.state.currentProject.buzzwords}
                    content={this.state.currentProject.writeUp}
                />
            </div>
        );
    }
};

export default PostEditor;