import React from 'react';

import CSS from './SideDrawer.module.css';

import NavItems from '../NavItems/NavItems';
import Backdrop from '../../UI/Backdrop/Backdrop';
import Aux from '../../../hoc/Aux/Aux';

const sideDrawer = (props) => {
    let attachedClasses = [CSS.SideDrawer, CSS.Close];

    if (props.open) {
        attachedClasses = [CSS.SideDrawer, CSS.Open];
    }

    return(
        <Aux>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ')}>
                <nav>
                    <NavItems />
                </nav>
            </div>
        </Aux>
    )
};

export default sideDrawer; 