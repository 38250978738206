import React from 'react';

import CSS from './DotLoader.module.css';

const dotLoader = () => (
    <div className={CSS.loader}>
        Loading...
    </div>
);

export default dotLoader; 