import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';

import { databaseRef } from '../../Firebase/Firebase';

import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import { BioEditorCancelModal, BioEditorSubmitModal } from './BioEditorModal/BioEditorModal';

import CSS from './BioEditor.module.css'

class BioEditor extends Component {
    state = {
        content: "Fuck\n\n# beef",
        showModal: false,
        modal: null
    }

    componentDidMount() {
        const aboutRef = databaseRef.ref('/about');
        
        aboutRef.once('value', (snapshot) => {
            this.setState({
                content: snapshot.val().content
            });
        });        
    }

    handleInputChange = (event) => {
        event.preventDefault();

        this.setState({
            content: event.target.value
        }) 
    }

    submitHandler = () => {
        let aboutRef = databaseRef.ref('/about');

        aboutRef.set({content: this.state.content})
    }

    handleShowModal =(modalIdentifier) => {
        let modalChildren = <p>Error</p>

        switch(modalIdentifier) {
            case('cancel'):
                modalChildren = <BioEditorCancelModal
                                    closeModalMethod={this.handleCloseModal}
                                />
                break;
            case('submit'):
                modalChildren = <BioEditorSubmitModal
                                    closeModalMethod={this.handleCloseModal}
                                    submitBioEditMethod={this.submitHandler}
                                />
                break;
            default:
                modalChildren = <p>Error</p>

        }

        this.setState({
            showModal: true,
            modal: modalChildren
        })
    }

    handleCloseModal = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        return (
            <div className={CSS.BioEditor}>
                <Modal 
                    closeModalMethod={this.handleCloseModal} 
                    show={this.state.showModal}
                >{this.state.modal}</Modal>
                <Markdown className={CSS.InfoContainer}>{this.state.content}</Markdown>
                <hr className={CSS.BioEditorHR}/>
                <textarea
                    rows="8"
                    className={CSS.BioEditorTextArea} 
                    value={this.state.content}
                    placeholder="Bio text" 
                    onChange={(event) => this.handleInputChange(event)}
                />
                <div className={CSS.ButtonContainer}>
                    <Button clicked={() => this.handleShowModal('cancel')}>Cancel</Button>
                    <Button clicked={() => this.handleShowModal('submit')}>Submit</Button>
                </div>
            </div>
        );
    }
};

export default BioEditor;