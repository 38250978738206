import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../../components/UI/Button/Button';
import Aux from '../../../../hoc/Aux/Aux';

import CSS from './AdminModal.module.css';

export const EditPostModal = (props) => (
    <div>
        <h1>Edit Post</h1>
        <p>Edit {props.projectData.title}?</p>
        <hr className={CSS.ModalHr}/>
        <div className={CSS.ButtonContainer}>
            <Button clicked={props.closeModalMethod}>Cancel</Button>
            <Link to={`post-editor/${props.projectData.key}`}>
                <Button>Edit</Button>
            </Link>
        </div> 
    </div>
);

export const DeletePostModal = (props) => (
    <div>
        <h1>Delete Post</h1>
        <p>Are you sure you want to delete this post?<br/><b>This cannot be undone.</b></p>
        <hr className={CSS.ModalHr}/>
        <div className={CSS.ButtonContainer}>
            <Button clicked={props.closeModalMethod}>Cancel</Button>
            <Button clicked={props.deletePostMethod}>Delete</Button>
        </div> 
    </div>
)

export const MetaDataModal = (props) => (
    <div>
        <h1>Project Details</h1>
        <div className={CSS.ModalInfo}>
            <p><b>Title:</b><br/>{props.projectData.title}</p>
            <p><b>Description:</b><br/>{props.projectData.description}</p>
            <p><b>Tags ({props.projectData.buzzwords.length}):</b><br/>{props.projectData.buzzwords.map((tag, i) => (<Aux key={tag}>"{tag}"{i === props.projectData.buzzwords.length - 1 ? null : ', '}</Aux>))}</p>
            <p><b>Links ({props.projectData.links.length}):</b><br/>{props.projectData.links.map((link, i) => (<Aux key={`${link.address}_${link.displayText}`}>{link.displayText}<br/> – {link.type === 'INTERNAL' ? `http://gmitchellgomez.com/#/${link.address}` : link.address}<br/></Aux>))}</p>
            <p><b>Date created:</b><br/>{props.projectData.timeData.dateCreated.date}</p>
            <p><b>Date last edited:</b><br/>{props.projectData.timeData.dateLastEdited.date}</p>
        </div>
        <div className={CSS.ButtonContainer}>
            <Button clicked={props.closeModalMethod}>Close</Button>
        </div> 
    </div>
)

export const ToggleVisibleModal = (props) => (
    <div>
        <h1>{props.projectData.visible ? 'Hide Post' : 'Show Post'}</h1>
        <p>Toggle post visibility</p>
        <hr style={{ width: '100%', border: '0.5px solid darkgray'}}/>
        <div className={CSS.ButtonContainer}>
            <Button clicked={props.closeModalMethod}>Cancel</Button>
            <Button clicked={props.toggleVisibleMethod}>{props.projectData.visible ? 'Hide' : 'Show'}</Button>
        </div>
    </div>
);