import React from 'react';

import CSS from './HeaderButton.module.css';

const headerButton = (props) => (
    <div 
        className={CSS.HeaderButton} 
        onClick={props.clicked}
    >{props.icon}</div>
);

export default headerButton; 