import React from 'react';
import { Link } from 'react-router-dom';
import { FaCaretRight } from 'react-icons/fa';

import Buzzword from '../../Buzzword/Buzzword';

import CSS from './InfoContainer.module.css';

const InfoContainer = (props) => (

    <div className={CSS.InfoContainer}>
        <Link
          to={`portfolio/${props.links[0].address}`}
        ><span className={CSS.Title}>{props.title}</span></Link>
        <Link
          to={`portfolio/${props.links[0].address}`}
        ><p className={CSS.Description}>
          {props.description}{" "}<Link
            to={`portfolio/${props.links[0].address}`}
            className={CSS.ReadMore}
          ><FaCaretRight/></Link>
          </p>
        </Link>
        <div className={CSS.BuzzwordContainer}>
          {
              props.buzzwords
              ? props.buzzwords.map((buzzword, i) => <Buzzword key={buzzword}>{buzzword}</Buzzword>)
              : null
          }
        </div>
    </div>
)

export default InfoContainer;
