import React from 'react'
import Home from "../Home/Home"
import About from "../About/About"
import Portfolio from "../Portfolio/Portfolio"

const frontPage = () => {
  return(<div>
    <Home/>
    <About/>
    <Portfolio/>
  </div>)
}

export default frontPage
