import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronCircleLeft } from 'react-icons/fa';

import TextHoverAnimator from '../../CustomAnimators/TextHoverAnimator/TextHoverAnimator';

import CSS from './ProjectNotFound.module.css';

const projectNotFound = () => (
    <div className={CSS.ProjectNotFound}>
        <div className={CSS.TextContainer}>
            <TextHoverAnimator string="Project" />
            <TextHoverAnimator string="Not" />
            <TextHoverAnimator string="Found" />
        </div>
        <Link to='/portfolio'>
            <div className={CSS.BackContainer}>
                <div className={CSS.BackButton}>
                    <FaChevronCircleLeft/>
                </div>
                <p>Back to portfolio</p>
            </div>
        </Link>
    </div>
);

export default projectNotFound; 