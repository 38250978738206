import React, { Component } from 'react';
import { authRef } from '../../../Firebase/Firebase';
import { Redirect } from 'react-router-dom';

import Button from '../../../components/UI/Button/Button';

import CSS from './Auth.module.css';

class Auth extends Component {
    state = {
        email: '',
        password: '',
        authorization: null,
        error: null
    }

    submitHandler = (email, password) => {
        authRef.signInWithEmailAndPassword(email, password)
                .then((response) => {
                    this.setState({authorization: response});
                })
                .catch((error) => {
                    this.setState({error: error});
                })
    }

    inputChangeHandler = (event, inputIdentifier) => {
        this.setState({
            [inputIdentifier]: event.target.value
        });
    }

    componentDidMount() {
        authRef.onAuthStateChanged((user) => {
            if (user) {
                this.setState({authorization: user})
            } else {
                this.setState({loading: false, user: null})
            }
        });
    }

    render() {
        if(this.state.authorization){
           return (<Redirect to='/admin' />)
        }

        return (
            <div className={CSS.LoginContainer}>
                <div className={CSS.AuthLogin}>
                    <form>
                        <h1>Admin</h1>
                        <label>
                            <input 
                                type="text" 
                                name="email" 
                                placeholder="username" 
                                value={this.state.email} 
                                onChange={(event) => this.inputChangeHandler(event, 'email')}
                            />
                        </label>
                        <label>
                            <input 
                                type="password" 
                                name="password" 
                                placeholder="password" 
                                value={this.state.password} 
                                onChange={(event) => this.inputChangeHandler(event, 'password')}
                            />
                        </label>
                        <p>{this.state.error ? this.state.error.message : null }</p>   
                        <br/>
                        <Button clicked={() => this.submitHandler(this.state.email, this.state.password)}>Log In</Button>
                    </form>
                </div>
            </div>
        );
    }
};

export default Auth;