import React, { Component } from 'react';
import { databaseRef } from '../../Firebase/Firebase.js';

import PortfolioCard from '../../components/PortfolioCard/PortfolioCard';
import Spinner from '../../components/UI/Spinner/Spinner';

import CSS from './Portfolio.module.css';

class Portfolio extends Component {
      state = {
          projects: [],
          loading: true
      }

      componentDidMount() {
          // initialize a reference to the projects child in the database
          let projectsRef = databaseRef.ref('projects/');

          //
          projectsRef.orderByChild('visible').equalTo(true).on('value', (snapshot) => {
              const snapshotValue = snapshot.val()
              if(snapshotValue !== null){
                  let projectsData = Object.keys(snapshotValue).map((key) => {
                      const { thumbnailImage, writeUp, visible, timeData, ...cardData } = snapshotValue[key];
                      return {
                          cardData: {...cardData},
                          thumbnailImage: thumbnailImage,
                          key: key
                      };
                  });

                  this.setState({
                      projects: projectsData,
                      loading: false
                  });
              } else {
                  this.setState({
                      loading: false
                  })
              }
          });
      }

      render() {
          return (
              <div className={CSS.Portfolio}>
                  {
                      (this.state.loading)
                      ?   <Spinner/>
                      :   this.state.projects.length > 0
                          ?   this.state.projects.map(project =>
                              <PortfolioCard
                                  key={project.key}
                                  hover
                                  image={project.thumbnailImage}
                                  info={project.cardData}
                              />)
                          :   <h1>No projects to display</h1>
                  }
              </div>
          );
      }
}

export default Portfolio;
