import React from 'react';
import { v1 as uuidv1 } from 'uuid';

import CSS from './AnimatedCharacter.module.css';

const animatedCharacter = (props) => {
    const map = Array.prototype.map;

    const letterArray = map.call(props.word, eachLetter => { return {char: `${eachLetter}`, key: uuidv1()}});

    return (
        <div>
        {letterArray.map((character) => <span key={character.key} className={CSS.AnimatedCharacter}>{character.char}</span>)}
        </div>
    );
}

export default animatedCharacter; 