import React, { Component } from 'react';

import Aux from '../../hoc/Aux/Aux'
import Toolbar from '../../components/Nav/Toolbar/Toolbar';
import SideDrawer from '../../components/Nav/SideDrawer/SideDrawer';
import Footer from '../../components/Footer/Footer';

class Layout extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer: false});
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return {
                showSideDrawer: !prevState.showSideDrawer
            }
        }
        )
    };

    render() {
        return(
            <div>
                {
                  this.props.loggedIn ?
                  <Aux>
                    <Toolbar drawerToggleClicked={() => this.sideDrawerToggleHandler()}/>
                    <SideDrawer
                        open={this.state.showSideDrawer}
                        closed={this.sideDrawerClosedHandler}
                    />
                  </Aux>
                  : null
                }
                {this.props.children}
                <Footer/>
            </div>
        )
    }
};

export default Layout;
