import React from 'react';

import Logo from '../../components/Logo/Logo';
import TextHoverAnimator from '../../components/CustomAnimators/TextHoverAnimator/TextHoverAnimator';
import ContactIcons from '../../components/ContactIcons/ContactIcons';

import CSS from './Home.module.css';

const home = () => (
    <div className={CSS.HomeContainer}>
        <div className={CSS.HomeContent}>
            <div className={CSS.HomeLogo}><Logo/></div>
            <div className={CSS.TitleContainer}>
                <TextHoverAnimator string="GABRIEL" />
                <TextHoverAnimator string="MITCHELL" />
                <TextHoverAnimator string="GOMEZ" />
                
                <span className={CSS.Title}>Front-End Developer</span>
                <br/>
                <br/>
                <ContactIcons />
            </div>
        </div>
    </div>
);

export default home;
