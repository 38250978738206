import React from 'react';

import CSS from './CatchAll.module.css';

const catchAll = (props) => (
    <div className={CSS.CatchAll}>
        <h1>{props.text}</h1>        
    </div>
);

export default catchAll; 