import React, { Component } from 'react';
import { databaseRef, storageRef, authRef } from '../../../Firebase/Firebase.js';
import { Link } from 'react-router-dom';
import { FaPlusSquare, FaUserEdit, FaSignOutAlt } from 'react-icons/fa'

import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import AdminCard from '../../../components/AdminCard/AdminCard';
import { EditPostModal, DeletePostModal, MetaDataModal, ToggleVisibleModal } from './AdminModal/AdminModal';

import CSS from './AdminPanel.module.css';

class AdminPanel extends Component {
    state = {
        projects: [],
        loading: true,
        showModal: false,
        modal: null
    }

    componentDidMount() {
        // initialize a reference to the projects child in the database
        let projectsRef = databaseRef.ref('projects/');
        
        //
        projectsRef.on('value', (snapshot) => {
            const snapshotVal = snapshot.val();
            if(snapshotVal !== null){
                let projectData = Object.keys(snapshotVal).map((key) => {
                    const projectData = snapshotVal[key]

                    return {
                        ...projectData,
                        key: key
                    };
                });

                this.setState({
                    projects: projectData,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        });
    }

    handleDeletePost = (projectData) => {
        let projectRef = databaseRef.ref(`projects/${projectData.key}`);
        projectRef.remove();

        const imagePaths = projectData.writeUp
        .filter(writeUpEl => writeUpEl.type === "image")
        .map(writeUpEl => writeUpEl.payload.path);
        
        imagePaths.push(projectData.thumbnailImage);
        imagePaths.push(`projects/${projectData.title}`);

        for(let path of imagePaths) {
            // Create a reference to the file to delete
            let imageRef = storageRef.ref().child(path);

            // Delete the file
            imageRef.delete().then((response) => {
                console.log(response);
            }).catch((error) => {
                console.log(error);
            });
        }

        this.handleCloseModal();
    }

    handleTogglePostVisibility = (projectData) => {
        let projectRef = databaseRef.ref(`projects/${projectData.key}`);
        
        projectRef.update({
            visible: !projectData.visible
        });

        this.handleCloseModal();
    }

    handleShowModal = (modalType, projectData) => {
        let modalChildren = null;
        
        switch(modalType) {
            case('edit'):
                modalChildren = <EditPostModal 
                                    projectData={projectData} 
                                    closeModalMethod={this.handleCloseModal}
                                />
                break;
            case('delete'):
                modalChildren = <DeletePostModal 
                                    projectData={projectData} 
                                    closeModalMethod={this.handleCloseModal}
                                    deletePostMethod={() => this.handleDeletePost(projectData)}
                                />
                break;
            case('metaData'):
                modalChildren = <MetaDataModal 
                                    projectData={projectData} 
                                    closeModalMethod={this.handleCloseModal}
                                />
                break;
            case('toggleVisible'):
                modalChildren = <ToggleVisibleModal 
                                    projectData={projectData} 
                                    closeModalMethod={this.handleCloseModal}
                                    toggleVisibleMethod={() => this.handleTogglePostVisibility(projectData)}
                                />
                break;
            default:
                modalChildren = <div><p>Error</p></div>
        }

        this.setState({
            showModal: true,
            modal: modalChildren
        })
    }

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            modal: null
        })
    }

    render() {
        return (
                
                    (this.state.loading) 
                        ? (<Spinner/>) 
                        : ( 
                            <div className={CSS.AdminPanel}>
                                <Modal closeModalMethod={this.handleCloseModal} show={this.state.showModal}>{this.state.modal}</Modal>
                                <div className={CSS.QuickActionsContainer}>
                                    <Link to="/post-editor">
                                        <div className={CSS.QuickAction}>
                                            <FaPlusSquare className={CSS.QuickActionIcon}/><p>Create Post</p>
                                        </div>
                                    </Link>
                                    <Link to="/bio-editor">
                                        <div className={CSS.QuickAction}>
                                            <FaUserEdit className={CSS.QuickActionIcon}/><p>Edit Bio</p>
                                        </div>
                                    </Link>
                                    <Link to="/sign-out">
                                        <div onClick={() => authRef.signOut()} className={CSS.QuickAction}>
                                            <FaSignOutAlt className={CSS.QuickActionIcon}/><p>Sign Out</p>
                                        </div>
                                    </Link>
                                    
                                </div>
                                <div className={CSS.PostDashBoard}>
                                {
                                    this.state.projects.map((projectData) => (
                                        <AdminCard 
                                            key={projectData.key}
                                            project={projectData}
                                            editMethod={() => this.handleShowModal('edit', projectData)}
                                            deleteMethod={() => this.handleShowModal('delete', projectData)}
                                            showMetaDataMethod={() => this.handleShowModal('metaData', projectData)}
                                            toggleVisibilityMethod={() => this.handleShowModal('toggleVisible', projectData)}
                                        />
                                    ))
                                }
                                </div>
                            </div>
                        )
                
            
        );
    }
};

export default AdminPanel;