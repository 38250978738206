import React from 'react';
import Markdown from 'markdown-to-jsx';
import Iframe from 'react-iframe';

import Buzzword from '../../components/Buzzword/Buzzword';
import ImageContainer from './WriteUpElements/ImageContainer/ImageContainer';

import CSS from './WriteUp.module.css';

/* expectedProps
    title: the title of the project
    description: a string with a short summary of the project
    content[]: an array of post elements, objects that  
                    in the following format
*/
    // writeUp: [
    //     {
    //         type: 'heading',
    //         payload: 'this is a heading'
    //     },
    //     {
    //         type: text'
    //         payload: 'this is a paragraph of text. you can read it right now!'
    //     },
    //     {
    //         type: 'image',
    //         payload: {
    //             file: [filename], 
    //             previewURL: [url], 
    //             caption: 'This is the image caption'
    //         }
    //     }
    // ]

const writeUp = (props) => {

        return(
            <div className={CSS.WriteUp}>
                <div className={CSS.Forward}>
                    <p className={CSS.Title}>{props.title}</p>
                    <p className={CSS.Caption}>{props.description}</p>
                </div>
                <div className={CSS.BuzzwordHolder}>
                    {
                        props.buzzwords
                        ? props.buzzwords.map((buzzword) => (<Buzzword key={buzzword}>{buzzword}</Buzzword>))
                        : null
                    }
                </div>
                <div className={CSS.Content}>
                    <div className={CSS.ContentContainer}>
                       {
                            props.content.map((postElement,i) => {
                                let renderedElement = null;

                                switch(postElement.type) {
                                    case('markdown'):
                                        renderedElement = <Markdown>{postElement.payload}</Markdown>
                                        break;
                                    case('uploaded-image'):
                                        renderedElement =   <ImageContainer 
                                                                image={postElement.payload.previewURL} 
                                                                caption={postElement.payload.caption}
                                                            />
                                        break;
                                    case('embed'):
                                        renderedElement =   <div className={CSS.IframeContainer}>
                                                                <Iframe 
                                                                    url={postElement.payload.url}
                                                                    className={CSS.Iframe}
                                                                />
                                                            </div>
                                        break;
                                    case('image'):
                                        renderedElement =   <ImageContainer 
                                                                image={postElement.payload.path} 
                                                                caption={postElement.payload.caption}
                                                            />
                                        break;
                                    default:
                                        renderedElement = null
                                }

                                return <div key={postElement.id}>{renderedElement}</div>;
                            })
                        }
                    </div>
                </div>
            </div>
        )
};

export default writeUp; 