import React from 'react';
import { NavLink } from 'react-router-dom';

import CSS from './NavItem.module.css';

const navItem = (props) => {
    
    const linkHolder = props.external ? (
        <a
            disabled={props.match}
            href={props.link} 
            target="_blank" 
            rel="noopener noreferrer"  
        >{props.children}</a>
    ) : (
        <NavLink 
            to={props.link} 
            activeClassName={CSS.active}
            exact={props.exact}
        >{props.children}</NavLink>
    );
    
    return (
        <div className={props.external ? [CSS.NavItem, CSS.MobileOnly].join(' ') : CSS.NavItem} onClick={props.clicked ? props.clicked : undefined}>
            <li>
                {linkHolder}
            </li>
        </div>
    );
};

export default navItem;