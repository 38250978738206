import React from 'react';

import AnimatedCharacter from './AnimatedCharacter/AnimatedCharacter';

import CSS from './TextHoverAnimator.module.css';

const TextHoverAnimator = (props) => {
    const map = Array.prototype.map;

    const wordArray = map.call(props.string.split("_"), eachWord => { return `${eachWord}`});

    return(
        <div className={CSS.TextHoverContainer}>
            {wordArray.map(word => <AnimatedCharacter key={word} word={word}/>)}
        </div>
    );
}

export default TextHoverAnimator; 