import React from 'react';
import { FaFrog as Openner } from 'react-icons/fa';

import CSS from './NavItems.module.css';
import NavItem from './NavItem/NavItem';
import pdf from '../../../assets/files/GabrielMGomez_Resume.pdf';

const navItems = (props) => (
    <ul className={CSS.NavItems}>
        <Openner className={CSS.Friend}/>
        <NavItem
            link="/"
            exact
        >home</NavItem>
        <NavItem
            link="/about"
        >about</NavItem>
        <NavItem
            link="/portfolio"
        >portfolio</NavItem>
        {
            props.signedIn
            ?   <NavItem
                    link="/admin" 
                >Admin</NavItem>
            :   null
        }
        <NavItem
            link="https://github.com/ggome009"
            external
        >Github</NavItem>
        <NavItem
            link="https://www.linkedin.com/in/gabriel-m-gomez/"
            external
        >LinkedIn</NavItem>
        <NavItem
            link="https://www.youtube.com/user/Mitchayyyyy"
            external
        >Youtube</NavItem>
        <NavItem
            link="mailto:gmgomez009@gmail.com"
            external
        >Email</NavItem>
        <NavItem
            link={pdf}
            external
        >Resume</NavItem>
    </ul>
);

export default navItems;