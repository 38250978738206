import React, { Component } from 'react';
import { FaChevronCircleLeft } from 'react-icons/fa';
import Markdown from 'markdown-to-jsx';
import { withRouter } from 'react-router-dom';
import Iframe from 'react-iframe';

import UserContext from '../../context/UserContext';

import { databaseRef } from '../../Firebase/Firebase';
import Spinner from '../../components/UI/Spinner/Spinner';
import Buzzword from '../../components/Buzzword/Buzzword';
import ImageContainer from './WriteUpElements/ImageContainer/ImageContainer';
import ProjectNotFound from './ProjectNotFound/ProjectNotFound';

import CSS from './WriteUp.module.css';

/* expectedProps
    title: the title of the project
    description: a string with a short summary of the project
    content[]: an array of post elements, objects that  
                    in the following format
*/
    // writeUp: [
    //     {
    //         type: 'heading',
    //         payload: 'this is a heading'
    //     },
    //     {
    //         type: text'
    //         payload: 'this is a paragraph of text. you can read it right now!'
    //     },
    //     {
    //         type: 'image',
    //         payload: {
    //             file: [filename], 
    //             previewURL: [url], 
    //             caption: 'This is the image caption'
    //         }
    //     }
    // <iframe width="560" height="315" src="https://www.youtube.com/embed/bKqUfLE5YOc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    // ]

class WriteUp extends Component {
    static contextType = UserContext;

    state = {
        currentProject: null,
        loading: true
    }

    goBack = () => {
        this.props.history.goBack();
    }

    componentDidMount() {
        let projectsRef = databaseRef.ref(`projects`);
        
        projectsRef.orderByChild('/links/0/address').equalTo(this.props.match.params.projectName).once('value', (snapshot) => {
            const snapshotValue = snapshot.val();

            if(snapshotValue !== null && (Object.values(snapshotValue)[0].visible || this.context.user)) {
                let projectData = Object.values(snapshotValue)[0];

                this.setState({
                    currentProject: projectData,
                    loading: false
                });
            } else {
                this.setState({
                    currentProject: 'Not found',
                    loading: false
                })
            }
        });
    }

    render() {
        return(
            this.state.loading
            ?   <Spinner/>
            :   this.state.currentProject === 'Not found'
                ?   <ProjectNotFound/>
                :   <div className={CSS.WriteUp}>
                        <div className={CSS.ContentContainer}>
                            <div className={CSS.BackButton} onClick={this.goBack}>
                                <FaChevronCircleLeft/>
                            </div>
                            <div className={CSS.Forward}>
                                <p className={CSS.Title}>{this.state.currentProject.title}</p>
                                <p className={CSS.Caption}>{this.state.currentProject.description}</p>
                            </div>
                            <div className={CSS.BuzzwordHolder}>
                                {
                                    this.state.currentProject.buzzwords
                                    ? this.state.currentProject.buzzwords.map((buzzword) => (<Buzzword key={buzzword}>{buzzword}</Buzzword>))
                                    : null
                                }
                            </div>
                            <div className={CSS.Content}>
                               {
                                    this.state.currentProject.writeUp.map((postElement) => {
                                        let renderedElement = null;
                                        console.log(postElement)
                                        switch(postElement.type) {
                                            case('markdown'):
                                                renderedElement =   <Markdown
                                                                        options={{
                                                                            overrides: {
                                                                                a: {
                                                                                    props: {
                                                                                        target: "_blank",
                                                                                        rel: "noopener noreferrer"  
                                                                                    }
                                                                                }
                                                                            }
                                                                        }} 
                                                                    >{postElement.payload}</Markdown>
                                                break;
                                            case('image'):
                                                renderedElement =   <ImageContainer 
                                                                        image={postElement.payload.path} 
                                                                        caption={postElement.payload.caption}
                                                                    />
                                                break;
                                            case('embed'):
                                                renderedElement =   <div className={CSS.IframeContainer}>
                                                                        <Iframe 
                                                                            url={postElement.payload.url}
                                                                            className={CSS.Iframe}
                                                                        />
                                                                    </div>
                                                break;
                                            default:
                                                renderedElement = null;
                                        }

                                        return <div key={postElement.id}>{renderedElement}</div>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
        )
    }
};

export default withRouter(WriteUp); 