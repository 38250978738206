import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../components/UI/Button/Button';

import CSS from './BioEditorModal.module.css';

export const BioEditorCancelModal = (props) => (
    <div>
        <h1>Are you sure you want to exit?</h1>
        <p>Any changes made to your about me page will be lost.</p>
        <hr className={CSS.ModalHr}/>
        <div className={CSS.ButtonContainer}>
            <Link to='admin'>
                <Button>Leave this page</Button>
            </Link>
            <Button clicked={props.closeModalMethod}>Stay on this page</Button>
        </div> 
    </div>
);

export const BioEditorSubmitModal = (props) => (
    <div>
        <h1>Submit changes?</h1>
        <hr className={CSS.ModalHr}/>
        <div className={CSS.ButtonContainer}>
            <Button clicked={props.closeModalMethod}>Cancel</Button>
            <Link to='admin'>
                <Button clicked={props.submitBioEditMethod}>Submit</Button>
            </Link>
        </div> 
    </div>
);