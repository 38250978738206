import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaCaretRight } from 'react-icons/fa';

import Aux from '../../hoc/Aux/Aux';
import PhotoContainer from './PhotoContainer/PhotoContainer'
import InfoContainer from './InfoContainer/InfoContainer';

import CSS from './PortfolioCard.module.css';

/*
    expected Props
        newCard:
            true if the card is for a completely new project not currently in the database
        title:
            the title of the project
        links:
            an array of objects in the following format
            two types of links
                INTERNAL links take you to another place within the same webapp
                EXTERNAL links take you to a different website, opened in a new tab
            1. Internal
                {
                    type: 'EXTERNAL',
                    address: 'https://github.com',
                    displayText: 'Github'
                }
            2. External
                {
                    type: 'INTERNAL',
                    address: 'some-path',
                    displayText: 'Some Path'
                }
        currentPath:
            used to direct a route to a sub path of the current path
        description:
            a string with a short summary of the project
        image:
            path to the image in the firebase storage bucket
        buzzwords:
            an array of strings that compose the rectangular tags
*/

class PortfolioCard extends Component {
    shouldComponentUpdate(nextProps) {
        return this.props.image !== nextProps.image || JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)
    }

    render(){
        return(
            <div className={CSS.PortfolioCard} style={{background: this.props.info.bg}}>
            <div className={CSS.PhotoLinkContainer}>
              <Link
                to={`portfolio/${this.props.info.links[0].address}`}
              >
                <PhotoContainer image={this.props.image}/>
              </Link>
              <div>{
                  this.props.info.links && this.props.info.links.length !== 0
                  ?   this.props.info.links.slice().reverse().map((link, i, array) => {
                          const displayedLink = (link.type === 'EXTERNAL')
                          ?   <a
                                  disabled={link.address === ""}
                                  className={CSS.ReadMore}
                                  href={link.address}
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >{link.displayText}</a>
                          :   <Link
                                  className={CSS.ReadMore}
                                  to={`portfolio/${link.address}`}
                              >Read More <FaCaretRight/></Link>
                          return (
                              <Aux key={`${i} - ${link.address}`}>
                                  {displayedLink}
                                  {(array.length - 1 === i) ? null : <Aux> - </Aux>}
                              </Aux>
                          );
                      })
                  :   null
                }</div>
            </div>
                <InfoContainer {...this.props.info}/>
            </div>
        )
    }
}

export default PortfolioCard;
