import React from 'react';

import ContactIcons from './ContactIcons/ContactIcons';

import CSS from './Footer.module.css';

const footer = () => (
    <div className={CSS.Footer}>
        <div>© Gabriel Gomez 2021</div>
        <ContactIcons />
    </div>
);

export default footer;
