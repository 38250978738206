import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../components/UI/Button/Button';

import CSS from './PostEditorModal.module.css';

const PostEditorModal = (props) => (
    <div>
        <h1>{props.confirmText} Post</h1>
        <p>{props.confirmText} <b>{props.title}</b>? {props.descriptiveText}</p>
        <hr className={CSS.ModalHr}/>
        <div className={CSS.ButtonContainer}>
            <Button clicked={props.closeModalMethod}>Cancel</Button>
            <Link to='/admin'>
                <Button clicked={props.confirmMethod}>{props.confirmText}</Button>
            </Link>
        </div> 
    </div>
);

export default PostEditorModal;