import React from 'react';

import Logo from '../../assets/images/logo.png';

import CSS from './Logo.module.css'

const logo = (props) => (
	<div className={CSS.Logo}>
		<img src={Logo} alt=""/>
	</div>
);

export default logo;