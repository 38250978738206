import React from 'react';

import HeaderButton from './HeaderButton/HeaderButton';

import CSS from './HeaderButtonRow.module.css';

const headerButtonRow = (props) => (
    <div style={props.style} className={CSS.HeaderButtonRow}>
        {props.buttons.map((button, i) => (<HeaderButton key={button.title} icon={button.icon} clicked={button.clicked}/>))}
    </div>
);

export default headerButtonRow; 